/** Converts a string date in ISO 8601 format to ´string´ dd/mm/yyy */
export const parseDate = (dateString: string): string => {
  const date = new Date(dateString);
  const day = date
    .getUTCDate()
    .toString()
    .padStart(2, "0");
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const year = date.getUTCFullYear().toString();

  return `${day}/${month}/${year}`;
};
